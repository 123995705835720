import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BytePipe } from 'src/app/pipes/byte.pipe';
import { SharedModule } from 'src/app/shared.module';
import { FileUploadStatus } from '../../vri-upload/file-upload-status';

/**
 * Based on https://github.com/telebroad/ngx-file-drag-drop/tree/master/projects/ngx-file-drag-drop/src/lib
 */
@Component({
  selector: 'app-file-selector',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileSelectorComponent),
      multi: true,
    },
  ],
  imports: [SharedModule, BytePipe],
  templateUrl: './file-selector.component.html',
  styleUrl: './file-selector.component.scss'
})
export class FileSelectorComponent {
  public readonly FileUploadStatus = FileUploadStatus;

  @HostBinding("class.disabled")
  @Input() public disabled: boolean = false;
  @Input() public multiple: boolean = false;
  @Input() public emptyPlaceholder = `Drop file${this.multiple ? "s" : ""} or click to select`;
  @Input() public accept = "*";

  @Input() public fileUploadPercentages: number[] = [];
  @Input() public fileErrors: (string | null)[] = [];

  @Output() private valueChanged = new EventEmitter<File[]>();

  @ViewChild("fileInputEl") private fileInputEl: ElementRef | undefined;

  @HostBinding('class.drag-over')
  public isDragOver: boolean = false;

  get files() {
    return this._files;
  }

  @HostBinding("class.empty-input")
  get isEmpty() {
    return !this.files?.length;
  }

  private _files: File[] = [];

  // https://angular.io/api/forms/ControlValueAccessor
  private _onChange = (_: File[]) => { };
  private _onTouched = () => { };

  @HostListener("change", ["$event"])
  public change(event: Event) {
    event.stopPropagation();
    this._onTouched();
    const fileList = (event.target as HTMLInputElement).files;
    if (fileList?.length) {
      this.addFiles(fileList);
    }
    // clear it so change is triggered if same file is selected again
    (event.target as HTMLInputElement).value = "";
  }

  @HostListener("dragenter", ["$event"])
  @HostListener("dragover", ["$event"])
  public activate(e: Event) {
    e.preventDefault();
    this.isDragOver = true;
  }

  @HostListener("dragleave", ["$event"])
  public deactivate(e: DragEvent) {
    e.preventDefault();
    this.isDragOver = false;
  }

  @HostListener("drop", ["$event"])
  public handleDrop(e: DragEvent) {
    this.deactivate(e);
    if (!this.disabled && e.dataTransfer) {
      const fileList = e.dataTransfer.files;
      this.removeDirectories(fileList).then((files: File[]) => {
        if (files?.length) {
          this.addFiles(files);
        }
        this._onTouched();
      });
    }
  }

  @HostListener("click")
  public open() {
    if (!this.disabled) {
      this.fileInputEl?.nativeElement.click();
    }
  }

  public writeValue(files: File[]): void {
    const fileArray = this.convertToArray(files);
    if (fileArray.length < 2 || this.multiple) {
      this._files = fileArray;
      this.emitChanges(this._files);
    } else {
      throw Error("Multiple files not allowed");
    }
  }

  public addFiles(files: File[] | FileList | File) {
    const fileArray = this.convertToArray(files);

    if (this.multiple) {
      // this.errorOnEqualFilenames(fileArray); TODO: implement
      const merged = this.files.concat(fileArray);
      this.writeValue(merged);
    } else {
      this.writeValue(fileArray);
    }
  }

  public removeFile(file: File) {
    const fileIndex = this.files.indexOf(file);
    if (fileIndex >= 0) {
      const currentFiles = this.files.slice();
      currentFiles.splice(fileIndex, 1);
      this.writeValue(currentFiles);
    }
  }

  public clear() {
    this.writeValue([]);
  }

  private emitChanges(files: File[]) {
    this.valueChanged.emit(files);
    this._onChange(files);
  }

  private removeDirectories(files: FileList): Promise<File[]> {
    return new Promise((resolve) => {
      const fileArray = this.convertToArray(files);
      const dirnames: string[] = [];
      const readerList = [];

      for (let i = 0; i < fileArray.length; i++) {
        const reader = new FileReader();

        reader.onerror = () => { dirnames.push(fileArray[i].name); };
        reader.onloadend = () => addToReaderList(i);
        reader.readAsArrayBuffer(fileArray[i]);
      }

      function addToReaderList(val: number) {
        readerList.push(val);
        if (readerList.length === fileArray.length) {
          resolve(
            fileArray.filter((file: File) => !dirnames.includes(file.name))
          );
        }
      }
    });
  }

  private convertToArray(files: FileList | File[] | File | null | undefined): File[] {
    if (files) {
      if (files instanceof File) {
        return [files];
      } else if (Array.isArray(files)) {
        return files;
      } else {
        return Array.prototype.slice.call(files);
      }
    }

    return [];
  }
}
