import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: "byte",
  standalone: true,
  pure: false
})
export class BytePipe implements PipeTransform {

  private formatter: Intl.NumberFormat;

  constructor(translateService: TranslateService) {
    this.formatter = new Intl.NumberFormat(translateService.currentLang, { maximumFractionDigits: 1 });
    translateService.onLangChange.subscribe(langChangeEvent => {
      this.formatter = new Intl.NumberFormat(langChangeEvent.lang, { maximumFractionDigits: 1 });
    });
  }

  public transform(value: string | number): string {
    let valueStr = value.toString();
    if (parseInt(valueStr, 10) >= 0) {
      valueStr = this.formatBytes(+valueStr);
    }
    return valueStr;
  }

  // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
  private formatBytes(bytes: number): string {
    if (bytes === 0) {
      return "0 bytes";
    }
    const k = 1024;
    const sizes = ["bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return this.formatter.format(bytes / Math.pow(k, i)) + " " + sizes[i];
  }
}
