<app-main-page-layout [pageLabel]="'GLOBAL.TITLE' | translate">
  <div class="container">
    <p> {{'AUTHORIZATION.NO_LICENSE_LINE_1' | translate}}</p>
    <p> {{'AUTHORIZATION.NO_LICENSE_LINE_2' | translate}}</p>
    <p>
      {{'AUTHORIZATION.NO_LICENSE_LINE_3_PRE_LINK' | translate }}
      <a href="#" (click)="logout()">{{'AUTHORIZATION.NO_LICENSE_LINE_3_LINK' | translate }}</a>
      {{'AUTHORIZATION.NO_LICENSE_LINE_3_POST_LINK' | translate }}
    </p>
  </div>
</app-main-page-layout>
