<mat-toolbar class="toolbar">
  <mat-toolbar-row class="toolbar-row">
    <a routerLink="/analysis-list" class="logo"><img alt="logo" src="/assets/headerlogo_wit.svg" /></a>
    <span class="page-name">{{pageLabel}} </span>
    <span class="spacer"></span>
    <span class="user-name">{{ userData?.name || userData?.email }}</span>
    <img class="avatar" data-cy="userMenu" alt="avatar" src="{{getUserMediaImageUrl()}}" [matMenuTriggerFor]="userMenu">
    <mat-menu class="header-menu-right" #userMenu="matMenu" xPosition="before">
      <div class="language-menu-item">
        <div class="language-label">{{'HEADER.USER.LANGUAGE' | translate}}</div>
        <div class="language-flags">
          @for (language of languages; track language) {
          <button mat-menu-item class="language-flag"
            matTooltip="{{'HEADER.USER.LANGUAGE.' + language.toUpperCase() | translate}}"
            (click)="onLanguageClick(language)">
            <img alt="flag icon {{language}}" src="/assets/i18n/{{ language }}.png">
            @if (currentLanguage === language) {
            <div class="language-selector"></div>
            }
          </button>
          }
        </div>
      </div>
      <a mat-menu-item href="https://www.goudappel.nl/{{currentLanguage}}/privacy-statement"
        target="_blank">{{'HEADER.USER.PRIVACY' | translate}}</a>
      <a mat-menu-item [href]="SUPPORT_URL">{{'HEADER.USER.FEEDBACK' | translate}}</a>
      <button mat-menu-item (click)="onLogoutClick()">{{'HEADER.USER.LOGOUT' | translate}}</button>
    </mat-menu>
    <app-app-drawer></app-app-drawer>
  </mat-toolbar-row>
</mat-toolbar>
