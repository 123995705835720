import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  private busySubject = new BehaviorSubject<boolean>(false);

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.hide();
      }
    });
  }

  get busy$(): Observable<boolean> {
    return this.busySubject.asObservable();
  }

  public show() {
    this.busySubject.next(true);
  }

  public hide() {
    this.busySubject.next(false);
  }

  public isBusy() {
    return this.busySubject.value;
  }

}
