<div class="file-drop-area" [class.empty]="files.length === 0">
  <input #fileInputEl class="hidden" #fileInput type="file" [attr.multiple]="multiple ? '' : null"
    [attr.accept]="accept" />
  @if (files.length === 0) {
  <span class="placeholder">{{ emptyPlaceholder }}</span>
  } @else {
  @for (file of files; track file; let index = $index) {
  <div class="file-line">
    @if (fileErrors[index] !== null) {
    <mat-icon class="file-error"
      matTooltip="{{ ('UPLOAD.FILE_ERROR.' + fileErrors[index]) | translate }}">error</mat-icon>
    }
    <div class="file-name">{{ file.name }}</div>
    <div class="file-size">{{ file.size | byte }}</div>
    <div class="file-actions">
      <button mat-icon-button (click)="removeFile(file); $event.stopPropagation()" [disabled]="disabled"
        matTooltip="{{ 'BUTTON.REMOVE' | translate }}">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <mat-progress-bar
    value="{{ index < fileUploadPercentages.length ? fileUploadPercentages[index] : 0 }}"></mat-progress-bar>
  }
  }
</div>
