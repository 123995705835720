import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class MessageService {

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {
  }

  public showErrorSnackBar(messageLangKey: string, langParams?: any) {
    this.snackBar.open(this.translateService.instant(messageLangKey, langParams),
      this.translateService.instant('BUTTON.CLOSE'), {
      duration: 0,
      panelClass: [messageLangKey.replace(/\./g, "-")] // Adds the language key as css class, so Cypress can target a specific error. Ugly, but effective enough
    });
  }

}
