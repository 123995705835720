<button class="app-drawer" mat-icon-button [matMenuTriggerFor]="appDrawerMenu" (click)="getAppDrawerContents()"
  matTooltip="{{ 'HEADER.MORE_APPS_TOOLTIP' | translate }}">
  <mat-icon>apps</mat-icon>
</button>
<mat-menu #appDrawerMenu="matMenu">
  <div class="loading" *ngIf="!drawerHtml">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
  <div class="mat-typography" [innerHTML]="drawerHtml"></div>
</mat-menu>

