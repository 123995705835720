<div class="form-container">
  <h6 mat-dialog-title class="header">
    {{ "META_DATA.MENU_TITLE" | translate }}
  </h6>

  <div mat-dialog-content class="input-grid">
    <div class="input-group">
      <mat-form-field class="input-field-full-width">
        <mat-label>{{ "META_DATA.TITLE" | translate }}</mat-label>
        <input matInput [(ngModel)]="metadataAndParameters.metadata.title" />
      </mat-form-field>

      <mat-form-field class="input-field-full-width">
        <mat-label>{{ "META_DATA.ROAD_AUTHORITY" | translate }}</mat-label>
        <input matInput [(ngModel)]="metadataAndParameters.metadata.roadAuthority" />
      </mat-form-field>

      <mat-form-field class="input-field-full-width">
        <mat-label>{{ "META_DATA.PROJECT_CODE" | translate }}</mat-label>
        <input matInput [(ngModel)]="metadataAndParameters.metadata.projectCode" />
      </mat-form-field>
    </div>

    <mat-form-field class="input-field-full-width">
      <mat-label>{{ "META_DATA.DESCRIPTION" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="metadataAndParameters.metadata.description"></textarea>
    </mat-form-field>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="parameter-title">{{ "PARAMETERS.SPIKETRESHOLD_TITLE" | translate }}
            <span *ngIf="metadataAndParameters.parameters.spikeThresholdMilliseconds !== null">*</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field class="input-field-full-width input-field-parameters">
          <mat-label>{{ "PARAMETERS.SPIKE_THRESHOLD" | translate:{default: spikeThresholdFields.defaultvalue} }}</mat-label>
          <input matInput type="number" [min]="spikeThresholdFields.min" [max]="spikeThresholdFields.max"
            [step]="spikeThresholdFields.step" [(ngModel)]="metadataAndParameters.parameters.spikeThresholdMilliseconds"
            #thresholdInput="ngModel" [ngModelOptions]="{updateOn: 'change'}"
            (ngModelChange)="applyValidators(thresholdInput, spikeThresholdFields.step)" />
          <span matTextSuffix>{{spikeThresholdFields.unit}}</span>
          <mat-error *ngIf="thresholdInput.errors?.['min'] && !thresholdInput.errors?.['notDividibleByStep']">
            "{{ "PARAMETERS.THRESHOLD_MIN_ERROR" | translate:{min: spikeThresholdFields.min, unit: 'ms'} }}"
          </mat-error>
          <mat-error *ngIf="thresholdInput.errors?.['max'] && !thresholdInput.errors?.['notDividibleByStep']">
            "{{ "PARAMETERS.THRESHOLD_MAX_ERROR" | translate:{max: spikeThresholdFields.max, unit: 'ms'} }}"
          </mat-error>
          <mat-error *ngIf="thresholdInput.errors?.['notDividibleByStep']">
            {{ "PARAMETERS.NOT_DIVISIBLE_BY_HONDERD_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="parameter-title">{{ "PARAMETERS.MOTORIZED_HEAD_DETECTOR" | translate }}
            <span *ngIf="isThresholdNull(metadataAndParameters.parameters.motorizedHeadDetectorParameters)">*</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let detectorParameterFields of motorizedHeadDetectorFields">
          <app-detector-parameter-field [detectorParameter]="detectorParameterFields"
            [parameter]="metadataAndParameters.parameters.motorizedHeadDetectorParameters"></app-detector-parameter-field>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="parameter-title">{{ "PARAMETERS.BICYCLE_HEAD_DETECTOR" | translate }}
            <span
              *ngIf="isThresholdNull(metadataAndParameters.parameters.bicycleHeadDetectorParameters)">*</span></mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let detectorParameterFields of bicycleHeadDetectorFields">
          <app-detector-parameter-field [detectorParameter]="detectorParameterFields"
            [parameter]="metadataAndParameters.parameters.bicycleHeadDetectorParameters"></app-detector-parameter-field>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="parameter-title">{{ "PARAMETERS.PUBLIC_TRANSPORT_HEAD_DETECTOR" | translate }}
            <span
              *ngIf="isThresholdNull(metadataAndParameters.parameters.ptHeadDetectorParameters)">*</span></mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let detectorParameterFields of publicTransportHeadDetectorFields">
          <app-detector-parameter-field [detectorParameter]="detectorParameterFields"
            [parameter]="metadataAndParameters.parameters.ptHeadDetectorParameters"></app-detector-parameter-field>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="parameter-title">{{ "PARAMETERS.STOP_CHANCE_GAP_TITLE" | translate }}
            <span *ngIf="metadataAndParameters.parameters.stopChanceGapThresholdMilliseconds !== null">*</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field class="input-field-full-width input-field-parameters">
          <mat-label>{{ "PARAMETERS.STOP_CHANCE_GAP_THRESHOLD" | translate:{default: stopChanceGapFields.defaultvalue} }}</mat-label>
          <input matInput type="number" [min]="stopChanceGapFields.min" [max]="stopChanceGapFields.max"
            [step]="stopChanceGapFields.step"
            [(ngModel)]="metadataAndParameters.parameters.stopChanceGapThresholdMilliseconds" #thresholdInput="ngModel"
            [ngModelOptions]="{updateOn: 'change'}"
            (ngModelChange)="applyValidators(thresholdInput, stopChanceGapFields.step)" />
          <span matTextSuffix>{{stopChanceGapFields.unit}}</span>
          <mat-error *ngIf="thresholdInput.errors?.['min'] && !thresholdInput.errors?.['notDividibleByStep']">
            "{{ "PARAMETERS.THRESHOLD_MIN_ERROR" | translate:{min: stopChanceGapFields.min, unit: 'ms'} }}"
          </mat-error>
          <mat-error *ngIf="thresholdInput.errors?.['max'] && !thresholdInput.errors?.['notDividibleByStep']">
            "{{ "PARAMETERS.THRESHOLD_MAX_ERROR" | translate:{max: stopChanceGapFields.max, unit: 'ms'} }}"
          </mat-error>
          <mat-error *ngIf="thresholdInput.errors?.['notDividibleByStep']">
            {{ "PARAMETERS.NOT_DIVISIBLE_BY_HONDERD_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="action-buttons">
      <div mat-dialog-actions>
        <button class="cancel-button" mat-button (click)="onCancel()">
          {{ "BUTTON.CANCEL" | translate }}
        </button>
        <button [disabled]="thresholdInput.errors" mat-flat-button (click)="onSubmit()">
          {{ "BUTTON.OK" | translate }}
        </button>
      </div>
    </div>

  </div>
</div>
