import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, LOCALE_ID, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LogLevel, provideAuth } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import type * as i18n_en from '../../public/assets/i18n/en.json';
import type * as i18n_nl from '../../public/assets/i18n/nl.json';
import { routes } from './app.routes';
import { authInterceptorFn } from './services/http/interceptors/auth-interceptor-fn';
import { errorInterceptorFn } from './services/http/interceptors/error-interceptor-fn';
import { DynamicLocaleId, LanguageUtils } from './utils/language-utils';

// Use TypeScript compiler to check for i18n omissions in language files (both should be the same)
declare class match_i18n<Expected, Actual extends Expected> {
}

declare const i18nChecks: [
  match_i18n<typeof i18n_en, typeof i18n_nl>, // NL matches EN ?
  match_i18n<typeof i18n_nl, typeof i18n_en> // EN matches NL ?
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([authInterceptorFn, errorInterceptorFn]),
    ),
    provideAnimationsAsync(),
    provideAuth({
      config: {
        authority: environment.fusionAuth.issuer,
        unauthorizedRoute: '/',
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.fusionAuth.clientId,
        scope: 'openid profile offline_access email https://graph.microsoft.com/user.read',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Warn,
      },
    }),
    importProvidersFrom(TranslateModule.forRoot(LanguageUtils.getTranslateModuleConfig())),
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
  ],
};
