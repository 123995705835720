import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResultDto } from 'src/app/dto/create-result-dto';
import { ResultDto } from 'src/app/dto/result-dto';
import { BaseHttpService } from './base-http.service';

type PostPresignedUrlsResponse = Record<string, string>;

@Injectable({
  providedIn: 'root',
})
export class VriUploadHttpService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getPresignedUrls(fileNames: string[]) {
    return this.postT<PostPresignedUrlsResponse>(`presigned-urls`, { filenames: fileNames });
  }

  public uploadFileToS3(file: File, url: string) {
    return this.uploadFileWithProgress(url, file);
  }

  public createResult(metadataAndParameters: CreateResultDto) {
    return this.postT<ResultDto>(`result`, metadataAndParameters)
      .pipe(this.mapDates());
  }

  public getResult() {
    return this.get<ResultDto>(`result`)
      .pipe(this.mapDates());
  }

  public downloadResult() {
    return this.downloadAsBlob(`result/download`);
  }
}
