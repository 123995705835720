import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { mergeMap, throwError } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { Constants } from '../../../utils/constants/constants';

export const authInterceptorFn: HttpInterceptorFn = (request, next) => {
  if (!request.url.startsWith(Constants.API_PREFIX)) {
    return next(request);
  }

  const authService = inject(AuthService);

  return authService.getAuthState().pipe(
    mergeMap((authState) => {
      if (authState !== 'OK') {
        return throwError(() => new Error('User is not authenticated or authorized'));
      }
      return authService.getAccessToken();
    }),
    mergeMap((accessToken) => {
      const cloned = request.clone({
        headers: request.headers.set(
          'Authorization',
          'Bearer ' + accessToken,
        ),
      });
      return next(cloned);
    }),
  );
};
