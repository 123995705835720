import { Component, Input } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SharedModule } from 'src/app/shared.module';

@Component({
  selector: 'app-page-spinner',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss'],
})
export class PageSpinnerComponent {

  @Input() message = 'GLOBAL.BUSY';

  constructor(public spinnerService: SpinnerService) { }

}
