import { Component, Input } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-main-page-layout',
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
  templateUrl: './main-page-layout.component.html',
  styleUrls: ['./main-page-layout.component.scss']
})
export class MainPageLayoutComponent {
  @Input() public pageLabel: string;
  @Input() public footer = false;
  @Input() public styledBackground = false;
}
