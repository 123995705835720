import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageUtils } from './utils/language-utils';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private readonly FIREFOX_BROWSER_NAME = 'firefox';

  constructor(private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document) {
    LanguageUtils.initLanguage(translateService);
  }

  public ngOnInit() {
    this.translateService.onLangChange.subscribe(langChangeEvent => {
      this.document.documentElement.lang = langChangeEvent.lang;
    });

    // Firefox has limited support for scrollbars that will override webkits scrollbar if applied
    if (this.isBrowser(this.FIREFOX_BROWSER_NAME)) {
      document.documentElement.classList.add(this.FIREFOX_BROWSER_NAME);
    }
  }

  private isBrowser(browserName: string): boolean {
    const regex = new RegExp(browserName, 'i');
    return regex.test(navigator.userAgent);
  }
}
