import { Component, Inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CreateResultDto } from 'src/app/dto/create-result-dto';
import { DetectorParametersDto } from 'src/app/dto/detector-parameter-dto';
import { ValidatorsService } from 'src/app/services/validation.service';
import { SharedModule } from 'src/app/shared.module';
import { DetectorParameterFieldComponent } from "../detector-parameter-input-field/detector-parameter-input-field.component";
import {
  BICYCLE_HEAD_DETECTOR_FIELDS,
  MOTORIZED_HEAD_DETECTOR_FIELDS,
  PUBLIC_TRANSPORT_HEAD_DETECTOR_FIELDS,
  SPIKE_THRESHOLD_FIELDS,
  STOP_CHANCE_GAP_FIELDS
} from './parameter-config';


@Component({
  selector: 'app-data-menu-dialog',
  standalone: true,
  imports: [SharedModule, MatFormFieldModule, MatInputModule, MatIconModule, DetectorParameterFieldComponent, MatDialogTitle, MatDialogContent, MatDialogActions],
  templateUrl: './properties-menu-dialog.component.html',
  styleUrl: './properties-menu-dialog.component.scss'
})
export class PropertiesDialogComponent {

  public metadataAndParameters: CreateResultDto;
  public spikeThresholdFields = SPIKE_THRESHOLD_FIELDS;
  public stopChanceGapFields = STOP_CHANCE_GAP_FIELDS;
  public motorizedHeadDetectorFields = MOTORIZED_HEAD_DETECTOR_FIELDS;
  public bicycleHeadDetectorFields = BICYCLE_HEAD_DETECTOR_FIELDS;
  public publicTransportHeadDetectorFields = PUBLIC_TRANSPORT_HEAD_DETECTOR_FIELDS;

  constructor(
    private validatorsService: ValidatorsService,
    public dialogRef: MatDialogRef<PropertiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateResultDto
  ) {
    this.metadataAndParameters = { ...data };
  }

  public isNotInteger: boolean = false;
  public isNotDivisibleByHundred: boolean = false;

  public applyValidators(thresholdInput: NgModel, step: number) {
    this.validatorsService.applyValidators(thresholdInput, [
      this.validatorsService.dividibleByStepValidator(step),
    ]);
  }
  public isThresholdNull(headDetectorParameters: DetectorParametersDto): boolean {
    return Object.values(headDetectorParameters).some(value => { return value !== null; });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.dialogRef.close(this.metadataAndParameters);
  }
}
