export interface ThresholdParameterConfig {
  label: string;
  defaultvalue: number;
  min: number;
  max: number;
  step: number;
  unit: string;
}

export interface DetectorParameterConfig {
  label: string;
  defaultvalue: number;
  parameter: DetectorParameterType;
  min: number;
  max: number;
  step: number;
  unit: string;
}

export type DetectorParameterType = 'overDetectionThresholdMinutes' | 'underDetectionThresholdMinutes' | 'flutterDetectionWindowSeconds' | 'flutterCountThreshold';

export const SPIKE_THRESHOLD_FIELDS: ThresholdParameterConfig = { label: 'PARAMETERS.SPIKE_THRESHOLD', defaultvalue: 200, min: 100, max: 1000, step: 100, unit: 'ms' };

export const STOP_CHANCE_GAP_FIELDS: ThresholdParameterConfig = { label: 'PARAMETERS.STOP_CHANCE', defaultvalue: 1600, min: 100, max: 5000, step: 100, unit: 'ms' };

export const MOTORIZED_HEAD_DETECTOR_FIELDS: DetectorParameterConfig[] = [
  { label: 'PARAMETERS.OVER_DETECTION_THRESHOLD', defaultvalue: 10, parameter: 'overDetectionThresholdMinutes', min: 1, max: 240, step: 1, unit: 'min' },
  { label: 'PARAMETERS.UNDER_DETECTION_THRESHOLD', defaultvalue: 720, parameter: 'underDetectionThresholdMinutes', min: 240, max: 5760, step: 1, unit: 'min' },
  { label: 'PARAMETERS.FLUTTER_DETECTION_WINDOW', defaultvalue: 60, parameter: 'flutterDetectionWindowSeconds', min: 30, max: 120, step: 1, unit: 'min' },
  { label: 'PARAMETERS.FLUTTER_COUNT_THRESHOLD', defaultvalue: 60, parameter: 'flutterCountThreshold', min: 15, max: 200, step: 1, unit: '#' }
];

export const BICYCLE_HEAD_DETECTOR_FIELDS: DetectorParameterConfig[] = [
  { label: 'PARAMETERS.OVER_DETECTION_THRESHOLD', defaultvalue: 10, parameter: 'overDetectionThresholdMinutes', min: 1, max: 240, step: 1, unit: 'min' },
  { label: 'PARAMETERS.UNDER_DETECTION_THRESHOLD', defaultvalue: 1440, parameter: 'underDetectionThresholdMinutes', min: 240, max: 5760, step: 1, unit: 'min' },
  { label: 'PARAMETERS.FLUTTER_DETECTION_WINDOW', defaultvalue: 60, parameter: 'flutterDetectionWindowSeconds', min: 30, max: 120, step: 1, unit: 'min' },
  { label: 'PARAMETERS.FLUTTER_COUNT_THRESHOLD', defaultvalue: 60, parameter: 'flutterCountThreshold', min: 15, max: 200, step: 1, unit: '#' }
];

export const PUBLIC_TRANSPORT_HEAD_DETECTOR_FIELDS: DetectorParameterConfig[] = [
  { label: 'PARAMETERS.OVER_DETECTION_THRESHOLD', defaultvalue: 10, parameter: 'overDetectionThresholdMinutes', min: 1, max: 240, step: 1, unit: 'min' },
  { label: 'PARAMETERS.UNDER_DETECTION_THRESHOLD', defaultvalue: 720, parameter: 'underDetectionThresholdMinutes', min: 240, max: 5760, step: 1, unit: 'min' },
  { label: 'PARAMETERS.FLUTTER_DETECTION_WINDOW', defaultvalue: 60, parameter: 'flutterDetectionWindowSeconds', min: 30, max: 120, step: 1, unit: 'min' },
  { label: 'PARAMETERS.FLUTTER_COUNT_THRESHOLD', defaultvalue: 60, parameter: 'flutterCountThreshold', min: 15, max: 200, step: 1, unit: '#' }
];
