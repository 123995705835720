export class LocalStorageConstants {

  private static readonly PREFIX = 'vri';

  public static readonly LANGUAGE = `${LocalStorageConstants.PREFIX}language`;

  public static readonly POST_AUTH_REDIRECT = `${LocalStorageConstants.PREFIX}post-auth-redirect`;

  public static readonly IS_DISCLAIMER_ACCEPTED = `${LocalStorageConstants.PREFIX}-accepted-disclaimer`;

}
