import { Component, Input } from '@angular/core';
import { saveAs } from 'file-saver';
import { finalize } from 'rxjs';
import { ResultDto } from 'src/app/dto/result-dto';
import { BytePipe } from 'src/app/pipes/byte.pipe';
import { VriUploadHttpService } from 'src/app/services/http/vri-upload-http.service';
import { SharedModule } from 'src/app/shared.module';

@Component({
  selector: 'app-vri-result',
  standalone: true,
  imports: [
    SharedModule,
    BytePipe
  ],
  templateUrl: './vri-result.component.html',
  styleUrl: './vri-result.component.scss'
})
export class VriResultComponent {

  @Input({ required: true }) public result: ResultDto;

  public downloading = false;

  constructor(private vriUploadHttpService: VriUploadHttpService) {
  }

  public download() {
    this.downloading = true;
    this.vriUploadHttpService.downloadResult()
      .pipe(finalize(() => this.downloading = false))
      .subscribe(blob => saveAs(blob, this.result.downloadFileName ?? 'result.zip'));
  }

}
