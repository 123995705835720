import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MainPageLayoutComponent } from 'src/app/components/shared/main-page-layout/main-page-layout.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-no-license',
  standalone: true,
  imports: [TranslateModule, MainPageLayoutComponent],
  templateUrl: './no-license.component.html',
  styleUrl: './no-license.component.scss'
})
export class NoLicenseComponent {

  constructor(private authService: AuthService) { }

  public logout() {
    this.authService.logoff();
    return false; // Needed to stop default link target #; otherwise logoff doesn't happen
  }
}
