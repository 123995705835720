import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { delayWhen, expand, finalize, forkJoin, of, retry, Subscription, switchMap, takeWhile, throwError, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CreateResultDto } from 'src/app/dto/create-result-dto';
import { ResultDto } from 'src/app/dto/result-dto';
import { VriUploadHttpService } from 'src/app/services/http/vri-upload-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SharedModule } from 'src/app/shared.module';
import { assertNotUndefined } from 'src/app/utils/assert';
import { Constants } from '../../utils/constants/constants';
import { FileSelectorComponent } from '../shared/file-selector/file-selector.component';
import { MainPageLayoutComponent } from '../shared/main-page-layout/main-page-layout.component';
import { PageSpinnerComponent } from '../shared/page-spinner/page-spinner.component';
import { VriResultComponent } from '../vri-result/vri-result.component';
import { FileError } from './file-error';
import { FileUploadStatus } from './file-upload-status';
import { PropertiesDialogComponent } from './properties-menu-dialog/properties-menu-dialog.component';
import { TotalUploadStatus } from './total-upload-status';

@Component({
  selector: 'app-vri-upload',
  standalone: true,
  imports: [
    SharedModule,
    FileSelectorComponent,
    MainPageLayoutComponent,
    PageSpinnerComponent,
    VriResultComponent,
  ],
  templateUrl: './vri-upload.component.html',
  styleUrl: './vri-upload.component.scss',
})
export class VriUploadComponent implements OnDestroy {

  private readonly UPLOAD_BATCH_SIZE = 250;
  private readonly POLL_DELAY_MS = 5000;

  private busySubscription: Subscription;

  public totalUploadStatus: TotalUploadStatus = TotalUploadStatus.NOT_STARTED;
  public totalUploadPercentage: number = 0;

  public fileUploadStatus: FileUploadStatus[] = [];
  public fileUploadPercentage: number[] = [];

  public files: File[] = [];
  public fileErrors: (FileError | null)[] = [];
  public hasErrors = false;

  public result: ResultDto | null = null;

  private processing = false;

  public metadataAndParameters: CreateResultDto = {
    metadata: { title: '', description: '', roadAuthority: '', projectCode: '' },
    parameters: {
      stopChanceGapThresholdMilliseconds: null,
      spikeThresholdMilliseconds: null,
      motorizedHeadDetectorParameters: {
        overDetectionThresholdMinutes: null,
        underDetectionThresholdMinutes: null,
        flutterDetectionWindowSeconds: null,
        flutterCountThreshold: null,
      },
      ptHeadDetectorParameters: {
        overDetectionThresholdMinutes: null,
        underDetectionThresholdMinutes: null,
        flutterDetectionWindowSeconds: null,
        flutterCountThreshold: null,
      },
      bicycleHeadDetectorParameters: {
        overDetectionThresholdMinutes: null,
        underDetectionThresholdMinutes: null,
        flutterDetectionWindowSeconds: null,
        flutterCountThreshold: null,
      },
    },
  };


  @ViewChild(FileSelectorComponent) private fileSelectorComponent!: FileSelectorComponent;

  constructor(private vriUploadHttpService: VriUploadHttpService, private spinnerService: SpinnerService, private dialog: MatDialog) {
  }

  public ngOnDestroy() {
    if (this.busySubscription) {
      this.busySubscription.unsubscribe();
    }
  }

  get busy() {
    return this.totalUploadStatus === TotalUploadStatus.IN_PROGRESS || this.processing;
  }

  public restart() {
    this.result = null;
    this.processing = false;
    setTimeout(() => this.clearUploads(), 1); // Make sure fileSelectorComponent is recreated first
  }

  public clearUploads() {
    this.fileSelectorComponent?.clear();
    this.validateFiles();
    this.fileUploadStatus.length = 0;
    this.fileUploadPercentage.length = 0;
    this.totalUploadStatus = TotalUploadStatus.NOT_STARTED;
    this.totalUploadPercentage = 0;
    this.processing = false;
  }

  public openMetadataDialog() {
    const dialogRef = this.dialog.open(PropertiesDialogComponent, {
      width: 'auto',
      data: this.metadataAndParameters,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.metadataAndParameters = result;
      }
    });
  }

  public uploadFiles(batchSize: number = this.UPLOAD_BATCH_SIZE) {
    const fileNamesToFiles = new Map(this.files.map(file => [file.name, file]));
    const fileNames = Array.from(fileNamesToFiles.keys());

    this.fileUploadStatus = Array(fileNames.length).fill(FileUploadStatus.NOT_SENT_YET);
    this.fileUploadPercentage = Array(fileNames.length).fill(0);
    this.totalUploadStatus = TotalUploadStatus.IN_PROGRESS;
    this.totalUploadPercentage = 0;

    const batches = this.chunkArray(fileNames, batchSize);

    this.vriUploadHttpService.clearProjectFiles()
      .subscribe({
        next: () => {
          this.uploadBatch(batches, 0, fileNamesToFiles);
        },
        error: (error) => {
          this.totalUploadStatus = TotalUploadStatus.COMPLETE_WITH_ERRORS;
        },
      });
  }


  private uploadBatch(
    batches: string[][],
    currentBatchIndex: number,
    fileNamesToFiles: Map<string, File>,
  ) {
    if (currentBatchIndex >= batches.length) {
      this.onAllBatchesComplete();
      return;
    }

    const currentBatch = batches[currentBatchIndex];

    this.vriUploadHttpService.getPresignedUrls(currentBatch).subscribe({
      next: (presignedUrlsResponse) => {
        const uploadObservables = currentBatch.map((fileName) => {
          const file = assertNotUndefined(fileNamesToFiles.get(fileName));
          const uploadUrl = presignedUrlsResponse[fileName];
          const index = this.files.findIndex((f) => f.name === fileName);
          return this.uploadFile(file, uploadUrl, index);
        });

        forkJoin(uploadObservables)
          .subscribe({
            next: () => {
              this.uploadBatch(batches, currentBatchIndex + 1, fileNamesToFiles);
            },
            error: () => {
              this.totalUploadStatus = TotalUploadStatus.COMPLETE_WITH_ERRORS;
            },
          });
      },
      error: () => {
        this.totalUploadStatus = TotalUploadStatus.COMPLETE_WITH_ERRORS;
      },
    });
  }

  private uploadFile(file: File, uploadUrl: string, index: number) {
    return this.vriUploadHttpService.uploadFileToS3(file, uploadUrl).pipe(
      switchMap((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.fileUploadStatus[index] = FileUploadStatus.SENT;
            break;
          case HttpEventType.UploadProgress: {
            this.fileUploadStatus[index] = FileUploadStatus.UPLOADING;
            this.fileUploadPercentage[index] = event.total
              ? Math.round((event.loaded * 100) / assertNotUndefined(event.total))
              : 0;
            this.recalculateTotalUploadProgress();
            break;
          }
          case HttpEventType.Response:
            this.fileUploadPercentage[index] = 100;
            if (event.ok) {
              this.fileUploadStatus[index] = FileUploadStatus.SUCCESS;
            } else {
              this.fileUploadStatus[index] = FileUploadStatus.ERROR;
              this.fileErrors[index] = FileError.UPLOAD_FAILED;
            }
            break;
          default:
            this.fileUploadStatus[index] = FileUploadStatus.PROCESSING;
            break;
        }
        return of(null);
      }),
      catchError((error) => {
        console.error(`Error while uploading: ${file.name}`, error);
        this.fileUploadStatus[index] = FileUploadStatus.ERROR;
        this.fileErrors[index] = FileError.UPLOAD_FAILED;
        return throwError(() => new Error('Failed to upload file ' + file.name));
      }),
      retry({
        count: 3,
        delay: (retryAttempt) => {
          console.warn(`Retrying upload for file: ${file.name}. Attempt #${retryAttempt + 1}`);
          return timer(1000);
        },
      }),
      finalize(() => this.refreshTotalUploadStatus())
    );
  }

  private onAllBatchesComplete() {
    if (this.totalUploadStatus === TotalUploadStatus.COMPLETE_SUCCESS && !this.processing) {
      this.startProcessing();
    }
  }

  private startProcessing() {
    this.spinnerService.show();
    this.vriUploadHttpService.createResult(this.metadataAndParameters)
      .subscribe(() => {
        this.fetchResult();
      });
  }

  private refreshTotalUploadStatus() {
    const isInProgress = this.fileUploadStatus.some(status => status === FileUploadStatus.SENT || status === FileUploadStatus.UPLOADING || status === FileUploadStatus.PROCESSING);
    const hasErrors = this.fileUploadStatus.some(status => status === FileUploadStatus.ERROR);
    const hasSuccesses = this.fileUploadStatus.some(status => status === FileUploadStatus.SUCCESS);

    if (isInProgress) {
      this.totalUploadStatus = TotalUploadStatus.IN_PROGRESS;
    } else if (hasErrors) {
      this.totalUploadStatus = TotalUploadStatus.COMPLETE_WITH_ERRORS;
    } else if (hasSuccesses) {
      this.totalUploadStatus = TotalUploadStatus.COMPLETE_SUCCESS;
    } else {
      this.totalUploadStatus = TotalUploadStatus.NOT_STARTED;
    }
  }

  private recalculateTotalUploadProgress() {
    const totalSize = this.files.reduce((acc, file) => acc + file.size, 0);
    const weightedProgress = this.files.reduce((acc, file, index) => acc + (file.size * this.fileUploadPercentage[index]), 0);
    const averageWeightedProgress = weightedProgress / totalSize;
    this.totalUploadPercentage = Math.round(averageWeightedProgress);
  }

  public handleFilesChange(files: File[]) {
    this.files = files;
    this.validateFiles();
  }

  private validateFiles() {
    this.fileErrors = this.files.map((file, index) => {
      if (!this.isValidExtension(file.name)) {
        return FileError.INCORRECT_TYPE;
      } else if (this.files.slice(0, index).map(f => f.name).includes(file.name)) {
        return FileError.DUPLICATE;
      } else {
        return null;
      }
    });
    this.hasErrors = this.fileErrors.some(err => err !== null);
  }

  private isValidExtension(fileName: string): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase() ?? '';
    return Constants.VALID_VRI_FILE_EXTENSIONS.includes(extension);
  }

  private fetchResult() {
    this.spinnerService.show();
    this.vriUploadHttpService.getResult()
      .subscribe(result => {
        if (result && result.result === 'PROCESSING') {
          this.pollForResult();
        } else {
          this.spinnerService.hide();
        }
        this.result = result;
      });
  }

  private pollForResult() {
    timer(0)
      .pipe(
        expand((_, i) => {
          const expDelay = (i < 5) ? this.POLL_DELAY_MS : (i < 10) ? this.POLL_DELAY_MS * 2 : this.POLL_DELAY_MS * 3;
          return of(null).pipe(delayWhen(() => timer(expDelay)));
        }),
        switchMap(() => this.vriUploadHttpService.getResult()),
        takeWhile(result => result.result === 'PROCESSING', true),
        finalize(() => this.spinnerService.hide()),
      )
      .subscribe(result => {
        if (result.result !== 'PROCESSING') {
          this.result = result;
          this.spinnerService.hide();
        }
      });
  }

  private chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }
}
