import { Routes } from '@angular/router';
import { NoLicenseComponent } from './auth/no-license/no-license.component';
import { VriUploadComponent } from './components/vri-upload/vri-upload.component';
import { authGuardFn } from './guards/auth.guards';

export const DEFAULT_WILDCARD_ROUTE = 'upload-vri';
export const routes: Routes = [
  {
    path: 'upload-vri',
    component: VriUploadComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'authorization/no-license',
    component: NoLicenseComponent,
  },
  {
    path: '**',
    redirectTo: DEFAULT_WILDCARD_ROUTE,
  },
];
