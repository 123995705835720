import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared.module';
import { LanguageUtils } from 'src/app/utils/language-utils';
import { AppDrawerComponent } from '../app-brower/app-drawer.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [SharedModule, AppDrawerComponent],
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private static readonly DEFAULT_USER_IMAGE = '/assets/default-user-img.jpg';
  public readonly SUPPORT_URL = 'mailto:support@dat.nl?subject=NVP Analyzer Feedback';

  @Input() public pageLabel: string;

  public readonly languages: string[] = LanguageUtils.SUPPORTED_LANGUAGES;
  public currentLanguage: string;
  public userData?: { name: string, email: string, picture: string; };

  constructor(
    private translateService: TranslateService,
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService,
  ) {
    this.currentLanguage = translateService.currentLang;
    this.oidcSecurityService.userData$.pipe(
      map(userData => userData.userData),
    ).subscribe(userdata => {
      this.userData = userdata;
    });
  }

  public onLanguageClick(language: string) {
    this.currentLanguage = language;
    LanguageUtils.changeLanguage(this.translateService, language);
  }

  public onLogoutClick() {
    this.authService.logoff();
  }

  public getUserMediaImageUrl() {
    return this.userData?.picture ?? HeaderComponent.DEFAULT_USER_IMAGE;
  }
}
