import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DetectorParametersDto } from 'src/app/dto/detector-parameter-dto';
import { ValidatorsService } from 'src/app/services/validation.service';
import { SharedModule } from 'src/app/shared.module';
import { DetectorParameterConfig } from '../properties-menu-dialog/parameter-config';


@Component({
  selector: 'app-detector-parameter-field',
  standalone: true,
  imports: [SharedModule, MatFormFieldModule, MatInputModule, MatIconModule],
  templateUrl: './detector-parameter-input-field.component.html',
  styleUrl: './detector-parameter-input-field.component.scss'
})
export class DetectorParameterFieldComponent {
  @Input() detectorParameter: DetectorParameterConfig;
  @Input() parameter: DetectorParametersDto;

  constructor(private validatorsService: ValidatorsService) { }

  public applyValidators(thresholdInput: any, step: number) {
    this.validatorsService.applyValidators(thresholdInput, [
      this.validatorsService.dividibleByStepValidator(step),
    ]);
  }
}
