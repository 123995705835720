// Taken from https://github.com/alexreardon/tiny-invariant/blob/master/src/tiny-invariant.ts

const prefix = 'Assertion failed';

// Throw an error if the condition fails
// > Not providing an inline default argument for message as the result is smaller
export function assert(
  condition: any,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message?: string | (() => string),
): asserts condition {
  if (condition) {
    return;
  }
  // Condition not passed

  const provided: string | undefined = typeof message === 'function' ? message() : message;

  // Options:
  // 1. message provided: `${prefix}: ${provided}`
  // 2. message not provided: prefix
  const value: string = provided ? `${prefix}: ${provided}` : prefix;
  throw new Error(value);
}

// Extended assertion utilities

export function assertIncludes<T>(array: readonly T[], value: any, message?: string | (() => string)): asserts value is T {
  assert(array.includes(value), message);
}

export function assertNotNull<T>(value: T | null, message?: string | (() => string)): T {
  assert(value !== null, message);
  return value;
};

export function assertNotUndefined<T>(value: T | undefined, message?: string | (() => string)): T {
  assert(value !== undefined, message);
  return value;
};
