import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map, tap } from 'rxjs';
import { DEFAULT_WILDCARD_ROUTE } from 'src/app/app.routes';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalStorageConstants } from 'src/app/utils/constants/local-storage-constants';
import { exhaustiveCheck } from 'src/app/utils/exhaustive-check';

function storeTargetUrlBeforeAuthentication(targetUrl: string) {
  if (targetUrl !== '/' + DEFAULT_WILDCARD_ROUTE) {
    localStorage.setItem(LocalStorageConstants.POST_AUTH_REDIRECT, targetUrl);
  }
}

function redirectToTargetUrl(router: Router, currentUrl: string) {
  const targetUrl = localStorage.getItem(LocalStorageConstants.POST_AUTH_REDIRECT);
  localStorage.removeItem(LocalStorageConstants.POST_AUTH_REDIRECT);
  if (targetUrl && targetUrl !== currentUrl) {
    return router.createUrlTree([targetUrl]);
  } else {
    return true;
  }
}

export const authGuardFn: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.getAuthState()
    .pipe(
      tap(authState => {
        if (authState !== 'OK') {
          storeTargetUrlBeforeAuthentication(state.url);
          console.debug(`Auth state is ${authState}`);
        }
      }),
      map(authState => {
        switch (authState) {
          case 'OK':
            return redirectToTargetUrl(router, state.url);
          case 'EXPIRED':
          case 'NO_REGISTRATION':
            return router.createUrlTree(['authorization/no-license']);
          case 'UNAUTHENTICATED':
            authService.login();
            return false;
          case 'UNKNOWN_ERROR':
            return false;
          default:
            throw exhaustiveCheck(authState);
        }
      })
    );
};
