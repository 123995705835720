<div class="result-container">

  <div class="result-type-with-download">
    <div class="result-type">
      @if (result.result === "SUCCESS") {
        <div class="result-success">
          <mat-icon>check_circle</mat-icon>
          <div>{{ 'RESULTS.SUCCESS' | translate }}</div>
        </div>
      } @else if (result.result === "PROCESSING") {
        <div class="result-processing">
          <mat-icon>sync</mat-icon>
          <div>{{ 'RESULTS.PROCESSING' | translate }}</div>
        </div>
      } @else {
        <div class="result-failed">
          <mat-icon>cancel</mat-icon>
          <div>{{ 'RESULTS.ERROR' | translate }}</div>
        </div>
      }
    </div>
    @if (result.downloadFileName) {
      <div class="button-container">
        <button mat-stroked-button class="download-button" [disabled]="downloading" (click)="download()">
          <div class="download-button-content">
            @if (downloading) {
              <mat-spinner [diameter]="28"></mat-spinner>
            } @else {
              <mat-icon>download</mat-icon>
            }
            <div class="label">
              <div>{{ result.downloadFileName }}</div>
              <div class="size">{{ (result.downloadSizeBytes ?? 0) | byte }}</div>
            </div>
          </div>
        </button>
      </div>
    }
  </div>

  <div class="message-scroller">
    @for (error of result.errors; track $index) {
      <div class="error">
        <mat-icon>error</mat-icon>
        <div>{{ error }}</div>
      </div>
    }

    @for (warning of result.warnings; track $index) {
      <div class="warning">
        <mat-icon>warning</mat-icon>
        <div>{{ warning }}</div>
      </div>
    }

    @for (notification of (result.notifications || []); track $index) {
      <div class="notification">
        <mat-icon>info_outline</mat-icon>
        <div>{{ notification }}</div>
      </div>
    }
  </div>

</div>
