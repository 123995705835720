import { HttpContextToken, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../../message.service';

export const errorInterceptorFn: HttpInterceptorFn = (request, next) => {

  const BYPASS_ERROR_INTERCEPTOR_TOKEN = new HttpContextToken(() => false);

  if (request.context.get(BYPASS_ERROR_INTERCEPTOR_TOKEN)) {
    return next(request);
  } else {
    const messageService = inject(MessageService);
    return next(request).pipe(catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        const errorResponse = err;
        console.log(errorResponse.statusText + ' - ' + errorResponse.message);
        messageService.showErrorSnackBar(getGeneralMessageKey(errorResponse.status));
      }
      return throwError(() => err);
    }));
  }

};

function getGeneralMessageKey(statusCode: number): string {
  switch (statusCode) {
    case 400:
      return 'SNACKBAR_HTTP_ERROR.STATUS_BAD_REQUEST';
    case 401:
      return 'SNACKBAR_HTTP_ERROR.STATUS_UNAUTHORIZED';
    case 403:
      return 'SNACKBAR_HTTP_ERROR.STATUS_FORBIDDEN';
    case 404:
      return 'SNACKBAR_HTTP_ERROR.STATUS_NOTFOUND';
    case 504:
      return 'SNACKBAR_HTTP_ERROR.STATUS_TIMEOUT';
    case 0:
      return 'SNACKBAR_HTTP_ERROR.STATUS_NONE';
    case 500:
    default:
      return 'SNACKBAR_HTTP_ERROR.STATUS_SERVER_ERROR';
  }
}
