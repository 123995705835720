<mat-form-field class="input-field-full-width input-field-parameters">
  <mat-label>{{ detectorParameter.label | translate:{default: detectorParameter.defaultvalue} }}</mat-label>
  <input matInput type="number" [min]="detectorParameter.min" [max]="detectorParameter.max"
    [step]="detectorParameter.step" [(ngModel)]="parameter[detectorParameter.parameter]" #thresholdInput="ngModel"
    [ngModelOptions]="{updateOn: 'change'}" (ngModelChange)="applyValidators(thresholdInput, detectorParameter.step)" />
  <span matTextSuffix>{{ detectorParameter.unit }}</span>
  <mat-error *ngIf="thresholdInput.errors?.['min'] && !thresholdInput.errors?.['notDividibleByStep']">
    "{{ "PARAMETERS.THRESHOLD_MIN_ERROR" | translate:{min: detectorParameter.min, unit: detectorParameter.unit} }}"
  </mat-error>
  <mat-error *ngIf="thresholdInput.errors?.['max'] && !thresholdInput.errors?.['notDividibleByStep']">
    "{{ "PARAMETERS.THRESHOLD_MAX_ERROR" | translate:{max: detectorParameter.max, unit: detectorParameter.unit} }}"
  </mat-error>
  <mat-error *ngIf="thresholdInput.errors?.['notDividibleByStep']">
    {{ "PARAMETERS.NOT_AN_INTEGER_ERROR" | translate }}
  </mat-error>
</mat-form-field>
