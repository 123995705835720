import { environment } from 'src/environments/environment';

export class Constants {

  private static readonly API_VERSION = 1;
  public static readonly API_PREFIX = `${environment.apiUri}/api/v${Constants.API_VERSION}`;

  public static readonly VALID_VRI_FILE_EXTENSIONS = ['vlg', 'vlog', 'vlt', 'vlc', 'c'];

}
