import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',  // Makes it available throughout the app
})
export class ValidatorsService {
  public dividibleByStepValidator(step: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value && value % step !== 0 ? { notDividibleByStep: true } : null;
    };
  }

  public applyValidators(thresholdInput: any, additionalValidators: ValidatorFn[] = []) {
    const control = thresholdInput.control;
    const existingValidator = control.validator;

    control.setValidators(
      existingValidator ? [existingValidator, ...additionalValidators] : additionalValidators
    );

    control.updateValueAndValidity();
  }
}
