<app-main-page-layout [pageLabel]="'GLOBAL.TITLE' | translate" [styledBackground]="true">
  <div class="container">

    <mat-card class="middle-column">

      @if (!result) {

      <mat-card-header>
        <mat-card-title>{{ 'UPLOAD.TITLE' | translate }}</mat-card-title>
      </mat-card-header>

      <mat-progress-bar [value]="totalUploadPercentage"
        [mode]="busy && totalUploadPercentage === 0 ? 'indeterminate' : 'determinate'"></mat-progress-bar>

      <app-file-selector [multiple]="true" [fileUploadPercentages]="fileUploadPercentage" [fileErrors]="fileErrors"
        [disabled]="busy" emptyPlaceholder="{{ 'UPLOAD.DROP_PLACEHOLDER' | translate }}"
        (valueChanged)="handleFilesChange($event)"></app-file-selector>

      <div class="button-container">
        <button mat-button (click)="clearUploads()"
          [disabled]="files.length === 0 || busy">{{ 'BUTTON.CLEAR' | translate }}</button>
        <div class="button-upload-metadata">
          <button class="meta-data-button" mat-button (click)="openMetadataDialog()"
            [disabled]="hasErrors || busy">{{ 'UPLOAD.META_DATA' | translate }}</button>
          <button mat-flat-button (click)="uploadFiles()"
            [disabled]="files.length === 0 || hasErrors || busy">{{ 'UPLOAD.UPLOAD_AND_PROCESS' | translate }}</button>
        </div>
      </div>

      } @else {

      <mat-card-header>
        <mat-card-title>{{ 'RESULTS.TITLE' | translate }}</mat-card-title>
      </mat-card-header>

      <app-vri-result [result]="result"></app-vri-result>

      <div class="new-upload">
        <button mat-button (click)="restart()">{{ 'RESULTS.NEW_UPLOAD' | translate }}</button>
      </div>
      }

    </mat-card>
  </div>

  <app-page-spinner></app-page-spinner>

</app-main-page-layout>
