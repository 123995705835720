/**
 * Used for compile-time checking whether a switch statement is exhaustive (all options are labeled).
 * This quickly finds switch statements which should be updated after an enum or discriminated union is
 * extended with new values.
 *
 * You should throw the result in the default block. This ensures proper function even if you return values
 * directly from the case blocks. It is sometimes needed to store the type in a singular variabele first. Example:
 * ```
 *   const mutationType = mutation.type;
 *   switch (mutationType) {
 *     case 'ABC': return foo();
*      case 'XYZ': return bar();
 *     default:
 *       throw exhaustiveCheck(mutationType);
 *   }
 * ```
 *
 * Note that no error is actually thrown because the error shows up at compile-time. It works because if all options
 * are exhausted, TypeScript narrows the type down to `never`, which is the required argument type of this function.
 */
export function exhaustiveCheck(type: never) {
  return new Error(`Unknown type: ${type}`);
}
