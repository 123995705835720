import { Environment } from './environment-interface';

/**
 * Local development environment settings. Change these according to your situation and save as `environment.development.ts`.
 */
export const environment: Environment = {
  production: false,
  apiUri: '', // goes via proxy.conf.json
  fusionAuth: {
    clientId: 'd628a37d-ea1f-4e1b-96d8-fd8abad0ec6c',
    issuer: 'https://staging.sso.dat.nl',
  },
};
